<template>
  <div class='divWrap'>
    <div
      class='div line1 fw500'
      :style="showLoginUser ? 'transform: translateX(0px);' : 'transform: translateX(-470px);'"
    >{{ animationLoginInfo.nickname }} 来了</div>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
export default {
  setup() {
    const roomId = ref("");
    const showLoginUser = ref(false);
    let loginUser = [];
    const sockedMes = (d) => {
      if (d.type === "join_live_room") {
        if (roomId.value == d.group_id) {
          loginUser = loginUser.concat(d.data.userInfo);
          loginEnd();
        }
      }
    };
    let loginTimeout = null;
    const animationLoginInfo = ref("");
    const loginEnd = () => {
      if (loginUser.length > 0) {
        if (!loginTimeout) {
          loginTimeout = true;
          showLoginUser.value = true;
          animationLoginInfo.value = loginUser[0];
          loginUser.splice(0, 1);
          setTimeout(() => {
            showLoginUser.value = false;
            nextTick(() => {
              setTimeout(() => {
                animationLoginInfo.value = "";
                loginTimeout = false;
                loginEnd();
              }, 1100);
            });
          }, 3200);
        }
      }
    };

    return {
      roomId,
      animationLoginInfo,
      showLoginUser,

      sockedMes,
    };
  },
};
</script>

<style lang="scss" scoped>
.divWrap {
  width: 386px;
  overflow: hidden;
  margin-bottom: 16px;
  height: 44px;
}

.divWrap .div {
  background: rgba(0, 0, 0, 0.3);
  padding: 0 20px;
  display: inline-block;
  max-width: 386px;
  height: 44px;
  color: #fff;
  line-height: 44px;
  transform: translateX(-470px);
  transition: transform 1s;
  /* animation: 4s div linear 1 normal; */
  font-size: 26px;
  background: linear-gradient(
    270deg,
    rgba(173, 140, 84, 0) 0%,
    rgba(173, 140, 84, 0.8) 100%
  );
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
}
</style>
