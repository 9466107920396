<template>
  <div class="comment" :class="{ 'show': showComment, 'high': isHigh }" :style="auctionData ? 'margin-bottom: ' + (130 / 75) + 'rem' : ''">
    <div
      class="list"
      id="commentEL"
      ref="commentView"
    >
      <template
        v-for="(item, index) in list"
        :key="`list${index}`"
      >
        <div
          v-if="item.msg_type == 'ht'"
          class="ht"
        >
          <div class="h"></div>
          <div class="i">{{item.content}}</div>
          <div class="h"></div>
        </div>
        <div v-else-if="item.msg_type == 'text'" class="comment-item flex">
          <div class="comment-bg-show">
            <div class="avatar" v-if="item.user && item.user.avatar">
              <img :src="item.user.avatar">
            </div>
            <div class="txt"><span class="text" v-if="item.user">{{ item.user.nickname }}：</span>{{ item.content }}</div>
          </div>
        </div>
        <div v-else-if="item.msg_type == 'auction_bid' && item.extend_data" class="comment-item flex">
          <div class="comment-bg-show">
            <div class="avatar" v-if="item.user && item.user.avatar">
              <img :src="item.user.avatar">
            </div>
            <div class="txt"><span class="text" v-if="item.user">{{ item.uid == 8888 ? '现场买家' : item.user.nickname + '买家' }}</span> 对<span v-if="item.extend_data.lot">LOT{{item.extend_data.lot}}号</span>拍品{{item.extend_data.store_name}} <span class="huang">出价{{formatPriceNumber(item.extend_data.bid_record.bid_price, 2)}}{{merchantInfo.fee_type_text}}</span></div>
          </div>
        </div>
        <div v-else-if="item.msg_type == 'auction_bid'" class="comment-item flex">
          <div class="comment-bg-show">
            <div class="avatar" v-if="item.user && item.user.avatar">
              <img :src="item.user.avatar">
            </div>
            <div class="txt"><span class="text" v-if="item.user">{{ item.user.nickname }}：</span>{{ item.content }}</div>
          </div>
        </div>
        <div v-else-if="item.msg_type == 'auction_success' && item.extend_data" class="comment-item flex tz">
          <div class="comment-bg-show">
            <div class="txt"><span class="text">系统消息</span>恭喜 {{item.extend_data.nickname}} 中拍了本件拍品，<span class="huang">中拍价{{formatPriceNumber(item.extend_data.now_price, 2)}}{{merchantInfo.fee_type_text}}</span></div>
          </div>
        </div>
        <div v-else class="comment-item flex tz">
          <div class="comment-bg-show">
            <div class="txt"><span class="text">系统消息</span>{{ item.content }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, nextTick, computed } from "vue";
import { useStore } from "vuex";
import { chatMsgList } from "@/api/live";
import { formatPriceNumber } from '@/utils/util';

export default {
  props: {
    auctionData: [String, Object],
    isHigh: String,
    merchant: Object,
  },
  setup(props) {
    const store = useStore();
    const commentView = ref(null);
    const roomId = ref("");
    const showComment = ref(false);

    onBeforeMount(() => {});

    const merchantInfo = computed(() => {
      if(props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const list = ref([]);
    const loadMsg = (id) => {
      roomId.value = id;
      chatMsgList({
        live_id: id,
        page: 1,
        limit: 50,
      }).then((res) => {
        list.value = res.data.list;
        nextTick(() => {
          commentView.value.scrollTop = commentView.value.scrollHeight;
          showComment.value = true;
        });
      });
    };

    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });

    let chatList = [];
    let pushChatTimeout = null;
    const sockedMes = (d) => {
      if (d.type === "send_msg") {
        d.data.user = d.data.userInfo;
        if (roomId.value == d.group_id && uid.value != d.data.uid) {
          chatList.push(d.data);
          if (pushChatTimeout) return false;
          pushChatTimeout = setTimeout(() => {
            const nowChat = JSON.parse(JSON.stringify(chatList));
            chatList = [];
            list.value = list.value.concat(nowChat);
            nextTick(() => {
              commentView.value.scrollTop = commentView.value.scrollHeight;
              pushChatTimeout = null;
              setTimeout(() => {
                if (list.value.length > 100) {
                  list.value.splice(0, list.value.length - 100);
                }
              }, 200);
            });
          }, 1000);
        } else if (roomId.value == d.group_id) {
          list.value.push(d.data);
          nextTick(() => {
            commentView.value.scrollTop = commentView.value.scrollHeight;
          });
        }
      } else if (d.type === "send_msg_new") {
        list.value.push(d.data);
        nextTick(() => {
          commentView.value.scrollTop = commentView.value.scrollHeight;
        });
      }
    };

    return {
      roomId,
      commentView,
      list,
      uid,
      merchantInfo,
      showComment,

      sockedMes,
      loadMsg,
      formatPriceNumber
    };
  },
};
</script>

<style lang="scss" scoped>
.comment {
  height: 360px;
  overflow: auto;
  position: relative;
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) 10%
  );
  transition: opacity 0.5s;
  opacity: 0;
  padding-left: 20px;
  &.high{
    height: 460px;

    .list{
      height: 460px;
    }
  }
}

.comment.show {
  opacity: 1;
}

.comment .list {
  height: 360px;
  overflow: auto;
}

.comment-item {
  width: 600px;
  box-sizing: border-box;
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 10px;
}

.comment-item .comment-bg-show {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  padding: 8px 16px 8px 8px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  display: flex;
}

.comment-item .avatar {
  margin-right: 8px;
}

.comment-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
}

.comment-item.tz .comment-bg-show {
  background: rgba(173, 140, 84, 0.4);
  border: 2px solid rgba(255, 237, 213, 0.3);
}

.comment-item.tz .text {
  font-size: 20px;
  line-height: 36px;
  display: inline-block;
  font-weight: 500;
  color: #9d6c12;
  padding: 0 10px;
  background: linear-gradient(180deg, #ffec8d 0%, #ffd355 100%);
  border-radius: 20px;
  color: #9d6c12;
  position: relative;
  bottom: 2px;
  margin-right: 8px;
  text-shadow: none;
}

.txt {
  padding-top: 2px;
  overflow: hidden;
  flex: 1;
}


.ht {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 20px;
  width: 500px;
  margin-left: 50px;
  height: 34px;
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 34px;
}

.ht .h {
  flex: 1;
  height: 2px;
  background: #fff;
}

.ht .i {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding: 0 10px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.13);
}
.hide {
  opacity: 0;
}


.huang {
  color: #FFF298;
}

.huang1 {
  color: #FFC970;
}

</style>
