<template>
  <div>
    <div
      class="all-bg"
      v-if="auctionData && auctionData.product"
    >
      <div
        class="top-bg"
        :style="`height:${422 / 75}rem;padding-top:${150 / 75}rem;background: url(${auctionData.product.image}!m640) no-repeat center;background-size: cover;`"
      ></div>
      <div
        class="bottom-bg"
        :style="`flex: 1;background: url(${auctionData.product.image}!m640) no-repeat center;background-size: cover;`"
        @click="hideAllAction"
      ></div>
    </div>
    <div
      class="left-bottom"
      :class="hideAll ? 'hide' : ''"
      @click="hideAllAction"
    >
      <div
        class="hide-view"
        v-if="hideAll"
        @click.stop="hideAction"
      ></div>
      <div class="bid-price-show">
        <div
          class="div flex align-items-c"
          :style="chujiaJsonFlag ? 'transform: translateX(0rem);' : 'transform: translateX(-6.26666rem);'"
        >
          <template v-if="chujiaJson">
            <div class="price-view flex-one">
              <div class="price-now-name">{{chujiaJson.user_info.nickname}}</div>
              <div class="price-now-price">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}{{formatPriceNumber(chujiaJson.pricestr, 2)}}</div>
            </div>
            <div class="price-now-avatar">
              <img
                :src="chujiaJson.user_info.avatar"
                mode="aspectFill"
              >
            </div>
          </template>
        </div>
      </div>
      <LoginNotive ref="loginNotiveEL" />
      <Comment
        ref="commentEL"
        :auctionData="auctionData"
      />
      <div class="album-bid-view">
        <template v-if="albumData && albumData.album_status != 3">
          <template v-if="albumData.bond_type == 1 && albumData.bond_price && !isPayBond">
            <div
              class="btn"
              @click.stop="payBondAction"
            >
              <div>交保证金</div>
            </div>
          </template>
          <template v-else-if="!userNumber">
            <div
              class="btn"
              @click.stop="useNumberPlateAction"
            >
              <div>办理号牌</div>
            </div>
          </template>
          <template v-else-if="auctionData && (auctionData.auction_status == 1 || auctionData.auction_status == 2)">
            <div class="number-info flex align-items-c">
              <div class="bid-view-top flex align-items-c">
                <div>我的号牌{{userNumber}}</div>
                <div
                  class="flex-one"
                  v-if="userNumberType == '特殊号牌' || userNumberType == '免保号牌'"
                >VIP免保证金</div>
                <div
                  v-else-if="albumData.bond_type == 2"
                  class="flex-one"
                >出价额度{{ formatPriceNumber(canBidPrice, 2) }}</div>
                <div
                  v-else-if="albumData.bond_type == 1"
                  class="flex-one"
                >
                  <span v-if="userNumberType == '普通号牌'">已缴纳保证金</span>
                  <span v-else>{{ userNumberType }}</span>
                </div>
                <div
                  v-if="albumData.bond_type == 2"
                  class="r flex align-items-c"
                  @click.stop="goRecharge"
                >
                  <div>去充值</div>
                  <img src="https://artimg2.artart.cn/image/20220413/0125a935e135c596c8f7ef76512e1c69.png">
                </div>
              </div>
            </div>
            <div class="bid-view">
              <div class="bid-view-bottom flex align-items-c">
                <div
                  class="jian item-flex-center"
                  @click.stop="jianAction"
                >
                  <img
                    src="https://artimg2.artart.cn/image/20220306/ebe1fd07f39aac46ecc3cf8467269941.png"
                    alt=""
                  >
                </div>
                <div
                  class="num flex-one fw500 flex align-items-c justify-c"
                  @click.stop="bidAction"
                  style="position: relative;"
                  :style="rightBtnStyle"
                >{{auctionData && auctionData.now_price ? '加 ' : '出价 '}}
                  <van-swipe
                    ref="vanSwiperBid"
                    class="bid-price-list"
                    :duration="200"
                    :loop="false"
                    :show-indicators="false"
                    :vertical="true"
                    :touchable="false"
                    :stop-propagation="false"
                    @touchmove.stop
                  >
                    <van-swipe-item
                      v-for="(m, i) in bidPriceList"
                      :key="`bid${i}`"
                    >
                      <div class="bid-p">{{ m.bidNextPriceCha }}</div>
                    </van-swipe-item>
                  </van-swipe>
                </div>
                <div
                  class="jia item-flex-center"
                  @click.stop="jiaAction"
                >
                  <img
                    src="https://artimg2.artart.cn/image/20220306/e7bb47145c070d5bffadc0b8df59cd16.png"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div
      class="btn-icon item-flex-center"
      :class="hideAll ? 'hide' : ''"
      @click="showListAction"
    >
      <img
        src="https://artimg2.artart.cn/image/20220413/24c55dfc627e6855cac655fb1bc73fb0.png"
        alt=""
      >
      <div
        v-if="albumData.auction_num"
        class="tag-view item-flex-center"
      >
        <div
          class="tag"
          style="background:#D5303E;color: #fff;"
        >{{ albumData.auction_num }}件</div>
      </div>
      <div
        class="hide-view"
        v-if="hideAll"
        @click.stop="hideAction"
      ></div>
    </div>

    <div
      class="btn-icon btn-icon1 item-flex-center"
      :class="hideAll ? 'hide' : ''"
      @click="goOrderAction"
    >
      <img
        class="img"
        src="https://artimg2.artart.cn/image/20220413/a44e4a5fc512d19baa4a6cd2943231b1.png"
        alt=""
      >
      <div
        v-if="orderWaitPay"
        class="tag-view item-flex-center"
      >
        <div
          class="tag"
          style="background: #D5303E;color: #fff;padding: 0 6rpx;"
        >待付{{ orderWaitPay }}</div>
      </div>
      <div
        class="hide-view"
        v-if="hideAll"
        @click.stop="hideAction"
      ></div>
    </div>

    <div
      class="auction-info"
      v-if="auctionData"
      :style="hideAll ? 'bottom: 0.90666rem;' : (albumData.bond_type == 1 && albumData.bond_price && !isPayBond) || (!userNumber) ? 'bottom: 3.41333rem;' : ''"
      @click="showDetailAction"
    >
      <div class="auction-info-top flex align-items-c">
        <div class="auction-info-status">
          <div
            v-if="auctionData.auction_status == 2"
            class="auction-status-view"
          >{{albumData.album_status == 2 ? '待开拍' : '预展中'}}</div>
          <div
            v-else-if="auctionData.auction_status == 1"
            class="auction-status-view"
          >竞拍中</div>
          <div
            v-else
            class="auction-statstatus-viewus"
          >已落槌</div>
        </div>
        <div class="auction-info-name line1"><span
            v-if="auctionData.lot"
            class="fw500"
          >LOT{{auctionData.lot}} </span>{{ auctionData.product?auctionData.product.store_name:""}}</div>
        <div
          class="auction-num"
          v-if="albumData.album_type != 1"
        ><text class="fw500">{{ auctionData.order_num }}</text>/{{ albumData.auction_num }}件</div>
      </div>
      <div class="auction-info-bottom flex align-items-c">
        <div class="flex-one flex align-items-c">
          <div
            class="auction-info-user flex align-items-c"
            v-if="auctionData.now_price"
          >
            <div
              class="user-avatar"
              v-if="auctionData.now_user_avatar"
            >
              <img :src="auctionData.now_user_avatar">
            </div>
            <div class="user-name line1">
              <template v-if="auctionData.now_user == uid">我的</template>
              <template v-else>{{ auctionData.now_user_name }}</template>
            </div>
            <div
              class="user-name"
              style="margin-left: 0;"
            >{{auctionData.auction_status > 2 ? '中拍' : '出价'}}</div>
          </div>
          <div
            class="auction-info-user flex align-items-c"
            v-else-if="auctionData.auction_status <= 2"
          >
            <div class="user-name">起拍价</div>
          </div>
          <div
            class="auction-info-price flex align-items-c"
            v-if="auctionData.now_price"
          >
            <template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(auctionData.now_price, 2) }}
          </div>
          <div
            class="auction-info-price flex align-items-c"
            v-else-if="auctionData.auction_status <= 2"
          >无底价</div>
          <div
            v-if="albumData.album_type != 1 && auctionData.auction_status == 1 && !auctionData.now_price"
            class="is-wait"
          >等待出价...</div>
          <div
            v-if="albumData.album_type != 1 && auctionData.now_price && auctionData.is_confirm === 0"
            class="is-confirm"
          >出价待确认...</div>
        </div>
        <div
          class="auction-count"
          v-if="auctionData.goTime"
        >
          <van-count-down :time="auctionData.goTime * 1000">
            <template #default="timeData">
              <div
                class="color-h"
                v-if="timeData.total / 1000 > 21600"
              >{{ auctionData.auction_status == 1 ? parseTime(auctionData.end_time, '{m}月{d}日 {h}:{i}') + ' 结拍' : parseTime(auctionData.start_time, '{m}月{d}日 {h}:{i}') + ' 开拍' }}</div>
              <div
                v-else-if="timeData.total > 0"
                :class="timeData.total / 1000 < 30 ? 'jjjp' : ''"
                class="item-flex-center color-h"
              >
                <div>{{auctionData.auction_status == 1 ? '距结拍' : '距开拍'}}</div>
                <div>{{timeData.hours ? formatDateNumber(timeData.hours) : ''}}:{{formatDateNumber(timeData.minutes)}}:{{formatDateNumber(timeData.seconds)}}</div>
              </div>
            </template>
          </van-count-down>

        </div>
      </div>
    </div>

    <BidBondMoney
      :showPay="showPayBondMoney"
      :albumData="albumData"
      @payOk="payOk"
      @payClear="payClear"
      :account="account"
    ></BidBondMoney>
    <AuctionList
      ref="auctionList"
      @showDetail="showDetail"
      :merchant="merchantInfo"
      :merchantid="merchantid" 
    />
    <AuctionDetailEL ref="auctionDetailEL" />
    <Congratulations ref="CongratulationsEL" />
    <CongratulationsMy ref="CongratulationsMyEL" />
    <div
      class="confirm-bid"
      v-if="showBidEL"
      @click="clearBidAction"
    >
      <div
        class="confirm-bid-content"
        @click.stop="kong"
      >
        <div class="confirm-bid-user flex align-items-c">
          <img
            :src="avatar"
            alt=""
          >
          <div>{{ uname }}</div>
        </div>
        <div class="confirm-bid-price">{{ formatPriceNumber(showBidPrice, 2) }}</div>
        <div class="confirm-bid-number">
          <div class="l">使用号牌{{ userNumber }}出价</div>
          <div class="r">
            <van-switch
              v-model="is_anonymous"
              :active-value="1"
              :inactive-value="0"
              @change="changeAnonymous"
            />
          </div>
        </div>
        <div class="confirm-bid-btns">
          <div
            class="confirm-bid-btn c"
            @click="clearBidAction"
          >取消出价</div>
          <div
            class="confirm-bid-btn q"
            @click="bidOkAction"
          >确认出价</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "./Comment.vue";
import LoginNotive from "./LoginNotive.vue";
import {
  albumDetail,
  checkAlbumBondPay,
  getLiveTopAuction,
  userNumberPlateHandle,
  auctionBidPrice,
  albumStartAuctionInfo,
  getLastUserBidRecord,
} from "@/api/auction";
import { userCenterNum, orderData } from "@/api/user";
import { getConfigClassKeys } from "@/api/system";
import {
  formatPriceNumber,
  settingPrice,
  parseTime,
  formatDateNumber,
} from "@/utils/util";
import { Toast, Swipe, SwipeItem, CountDown, Switch } from "vant";
import { ref, computed, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import BidBondMoney from "../../auction/modules/BidBondMoney.vue";
import AuctionList from "./AuctionList.vue";
import AuctionDetailEL from "./AuctionDetail.vue";
import Congratulations from "./Congratulations.vue";
import CongratulationsMy from "./CongratulationsMy.vue";

export default {
  components: {
    LoginNotive,
    Comment,
    BidBondMoney,
    AuctionList,
    AuctionDetailEL,
    Congratulations,
    CongratulationsMy,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [CountDown.name]: CountDown,
    [Switch.name]: Switch,
  },
  props: {
    hideAll: Boolean,
    merchant: Object,
    merchantid: Number,
  },
  setup(props, content) {
    const { hideAll } = toRefs(props);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const auctionDetailEL = ref(null); // 拍品详情组件
    const CongratulationsEL = ref(null); // 恭喜别人中拍
    const CongratulationsMyEL = ref(null); // 我中拍
    const roomId = ref(""); // 直播间id
    const albumId = ref(""); // 专场id
    const status = ref(0); // 专场状态
    const albumData = ref(""); // 专场信息
    const isPayBond = ref(false); // 是否缴纳保证金
    const auctionData = ref(""); // 拍品信息
    const auctionList = ref(null); // 拍品列表组件
    const compel_anonymous = ref(1);

    const chujiaJsonFlag = ref(false); // 出价效果控制器
    const chujiaJson = ref(null); // 最新出价内容
    const chujiaTimeout = ref(null); // 出价效果样式控制器

    const loadMsgAction = (id) => {
      commentEL.value.loadMsg(id);
      loginNotiveEL.value.roomId = id;
    };

    const merchantInfo = computed(() => {
      if(props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });
    const merchantid = ref(props.merchantid);
    const loadAlbumDetail = () => {
      // 获取配置
      getConfigClassKeys("auction_rule_set").then((res) => {
        compel_anonymous.value = res.data.compel_anonymous;
      });
      // 专场详情
      albumDetail(albumId.value)
        .then((res) => {
          const d = res.data;
          status.value = d.album_status;

          if (d.bond_type == 1 && d.bond_price) {
            isPayBond.value = d.isPayBond;
          }
          albumData.value = d;
          loadNowAuction();
        })
        .catch((message) => Toast(message));
      loadCount();
    };

    const loadNowAuction = () => {
      let action = getLiveTopAuction;
      if (
        albumData.value &&
        (albumData.value.album_type == 2 || albumData.value.album_type == 3)
      ) {
        action = albumStartAuctionInfo;
      }
      // 获取当前拍品
      action({ album_id: albumId.value }).then((res) => {
        if (res.data && res.data.product_id) {
          if (
            res.data.now_price &&
            albumData.value.album_type == 1 &&
            res.data.bid_record
          ) {
            res.data.now_user_name = res.data.bid_record.user.nickname;
            res.data.now_user_avatar = res.data.bid_record.user.avatar;
          } else if (
            (albumData.value.album_type == 2 ||
              albumData.value.album_type == 3) &&
            res.data.bid_record &&
            res.data.bid_record[0]
          ) {
            res.data.now_user_name = res.data.bid_record[0].user.nickname;
            res.data.now_user_avatar = res.data.bid_record[0].user.avatar;

            if (albumData.value.album_type == 3) {
              res.data.now_price = res.data.bid_record[0].bid_price;
              res.data.is_confirm = res.data.bid_record[0].is_confirm;
            }
          }
          auctionData.value = res.data;
          initPriceRule(res.data);
          initPrice(res.data);
          loadLastUserBidRecord();
        }
        
      });
    };

    const checkAlbumBondPayAction = () => {
      // 检查是否缴纳保证金
      checkAlbumBondPay(albumId.value).then((res) => {
        isPayBond.value = res.data.is_pay;
      });
    };
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 右按钮样式
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    const showPayBondMoney = ref(false);
    const payBondAction = () => {
      if (!userNumber.value) {
        useNumberPlateAction();
      }
      showPayBondMoney.value = true;
    };

    // 获取号牌
    const userNumber = computed(() => {
      return albumData.value.numberPlate && albumData.value.numberPlate.number
        ? albumData.value.numberPlate.number
        : "";
    });

    // 获取号牌
    const userNumberType = computed(() => {
      return albumData.value.numberPlate &&
        albumData.value.numberPlate.number_type
        ? albumData.value.numberPlate.number_type
        : "";
    });

    // 获取用户可出价额度
    const canBidPrice = computed(() => {
      return albumData.value.bond_type === 2
        ? (store.getters.account.balance || 0) * albumData.value.bond_bid_ratio
        : 0;
    });

    // 余额
    const account = computed(() => {
      return store.getters.account;
    });

    // 获取号牌
    const useNumberPlateAction = () => {
      if (albumData.value.album_config.check_user_idCard == 2) {
        store
          .dispatch("user/applyVerifyInfo")
          .then((type) => {
            if (type == 1 || type == 2) {
              userNumberAction();
            }
          })
          .catch((message) => Toast(message));
        return false;
      }
      userNumberAction();
    };

    const userNumberAction = () => {
      userNumberPlateHandle({
        venue_id: albumData.value.venue_id,
      })
        .then((res) => {
          albumData.value.numberPlate = res.data;
        })
        .catch((message) => Toast(message));
    };

    const vanSwiper = ref(null); // 出价swiper

    const bidPriceList = ref([]);
    const priceIndex = ref(0);
    const lock = ref(false);
    const upBid = ref(null);

    const vanSwiperBid = ref(null);

    const priceData = ref([{ min: 0, max: "∞", price: 1000, type: 1 }]);

    const getPrice = (p, r) => {
      // 获取计算后的价格
      const price = settingPrice(p, priceData.value);

      setPrice(
        {
          bidNextPrice: Number(price),
          bidNextPriceCha: formatPriceNumber(
            Number(price) - Number(auctionData.value.now_price),
            2
          ),
        },
        r
      );
    };

    const setPrice = (data, refresh) => {
      bidPriceList.value = refresh ? [data] : bidPriceList.value.concat([data]);
      if (priceIndex.value == -1) {
        lock.value = false;
      } else {
        priceIndex.value = bidPriceList.value.length - 1;
        if (vanSwiperBid.value) {
          vanSwiperBid.value.swipeTo(priceIndex.value);
        }
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const initPriceRule = (newVal) => {
      if (newVal.bid_range_id && newVal.bid_type == 2) {
        if (newVal.bidRange) {
          priceData.value = newVal.bidRange.content;
        }
      } else {
        priceData.value = [
          { min: 0, max: "∞", price: Number(newVal.plus_price), type: 1 },
        ];
      }
    };
    const initPrice = (newVal) => {
      if (newVal && newVal.product_id) {
        if (newVal.now_price) {
          getPrice(newVal.now_price, 2);
        } else {
          if (upBid.value) clearTimeout(upBid.value);
          lock.value = true;
          if (newVal.start_price) {
            setPrice(
              {
                bidNextPrice: Number(newVal.start_price),
                bidNextPriceCha: formatPriceNumber(
                  Number(newVal.start_price) - Number(newVal.now_price),
                  2
                ),
              },
              1
            );
          } else {
            getPrice(0, 1);
          }
        }
      }
    };

    const jiaAction = () => {
      if (lock.value) return false;
      lock.value = true;
      if (priceIndex.value + 1 >= bidPriceList.value.length) {
        getPrice(bidPriceList.value[priceIndex.value].bidNextPrice);
      } else {
        priceIndex.value = priceIndex.value + 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const jianAction = () => {
      if (priceIndex.value > 0) {
        if (lock.value) return false;
        lock.value = true;
        priceIndex.value = priceIndex.value - 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };
    const commentEL = ref(null); // 聊天列表组件
    const loginNotiveEL = ref(null); // 登录用户展示组件
    const sockedMes = (d) => {
      // 接收socket通知
      if (d.type === "send_msg" || d.type === "send_msg_new") {
        commentEL.value.sockedMes(d);
        return false;
      }
      if (d.type === "join_live_room") {
        loginNotiveEL.value.sockedMes(d);
        return false;
      }
      if (d.type === "auction_top") {
        // 拍品置顶
        auctionData.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
        loadLastUserBidRecord();
      } else if (d.type === "auction_bid" || d.type === "auction_bid_proxy") {
        // 拍品出价
        if (auctionData.value.product_id == d.data.id) {
          const data = auctionData.value;
          if (d.data.goTime) data.goTime = d.data.goTime;
          let record = d.data.bid_record;
          if (d.type === "auction_bid_proxy") {
            record = d.data.bid_record_list[0];
          }
          if (albumData.value.album_type == 3) {
            data.now_price = record.bid_price;
            data.is_confirm = record.is_confirm;
          } else {
            data.now_price = d.data.now_price;
          }
          if (d.type === "auction_bid") {
            data.now_user = d.data.bid_record.uid;
            data.now_user_name = d.data.bid_record.user.nickname;
            data.now_user_avatar = d.data.bid_record.user.avatar;
          } else {
            data.now_user = d.data.bid_record_list[0].uid;
            data.now_user_name = d.data.bid_record_list[0].user.nickname;
            data.now_user_avatar = d.data.bid_record_list[0].user.avatar;
          }
          auctionData.value = data;
          getPrice(data.now_price, 2);
          chujiaJsonFlag.value = true;
          chujiaJson.value = {
            pricestr: data.now_price,
            user_info: record.user,
          };
          if (chujiaTimeout.value) clearTimeout(chujiaTimeout.value);
          chujiaTimeout.value = setTimeout(() => {
            chujiaJsonFlag.value = null;
          }, 3000);
        }
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        if (auctionData.value.product_id == d.data.id) {
          const data = auctionData.value;
          data.now_price = d.data.now_price;
          data.now_user = d.data.bid_list[0].uid;
          data.now_user_name = d.data.bid_list[0].user.nickname;
          data.now_user_avatar = d.data.bid_list[0].user.avatar;
          auctionData.value = data;
          getPrice(d.data.now_price, 2);

          setSystemAction(`价格无效，请重新出价`);
        }
      } else if (d.type === "auction_bid_confirm") {
        // 确认网络出价
        if (auctionData.value.product_id == d.data.id) {
          const data = auctionData.value;
          const record = d.data.bid_record;

          if (albumData.value.album_type == 3) {
            data.now_price = record.bid_price;
            data.is_confirm = record.is_confirm;
          } else {
            data.now_price = d.data.now_price;
          }
          data.now_user = record.uid;
          data.now_user_name = record.user.nickname;
          data.now_user_avatar = record.user.avatar;

          auctionData.value = data;
          getPrice(data.now_price, 2);
        }
      } else if (d.type === "auction_start") {
        // 确认起拍价
        Toast("拍品可以出价了");
        auctionData.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_end" || d.type === "auction_revoke") {
        // 拍品结束
        if (d.data.next_auction) {
          // 有下一件拍品
          if (d.type === "auction_revoke") {
            albumData.value.auction_num = d.data.auction_count;
          }
          if (
            d.type === "auction_end" &&
            d.data.auction_info &&
            d.data.auction_info.auction_status > 4
          ) {
            const old = d.data.auction_info;
            if (old.userInfo.uid == uid.value) {
              CongratulationsMyEL.value.transaction = {
                uname: old.userInfo.nickname,
                lot: old.lot,
                transactionPrice: formatPriceNumber(old.transaction_price, 2),
                price: formatPriceNumber(old.now_price, 2),
                order_id: old.order_id,
              };
              loadCount();
            } else {
              CongratulationsEL.value.transaction = {
                uname: old.userInfo.nickname,
                lot: old.lot,
                price: formatPriceNumber(old.now_price, 2),
              };
              setTimeout(() => {
                CongratulationsEL.value.transaction = null;
              }, 5000);
            }
          }
          auctionData.value = d.data.next_auction;
          initPriceRule(d.data.next_auction);
          initPrice(d.data.next_auction);
          loadLastUserBidRecord();
        } else {
          auctionData.value = "";
        }
        setSystemAction("本件已结拍", 1);
      } else if (d.type === "auction_remake") {
        // 重拍
        setSystemAction("拍品重新开始拍卖");
        auctionData.value = d.data.auction_info;

        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
        loadLastUserBidRecord();
      } else if (d.type === "auction_edit_bidRange") {
        // 切换竞价阶梯
        if (auctionData.value.product_id == d.data.auction_id) {
          setSystemAction("加价幅度已修改");
          const auctionD = auctionData.value;

          auctionD.bid_type = d.data.bid_type;
          if (d.data.bid_type == 1) {
            auctionD.plus_price = d.data.plus_price;
          } else {
            auctionD.bid_range_id = d.data.bidRange_info.id;
            auctionD.bidRange = d.data.bidRange_info;
          }

          auctionData.value = auctionD;
          initPriceRule(auctionD);
          initPrice(auctionD);
        }
      } else if (d.type === "auction_notice") {
        // 系统通知
        if (Number(roomId.value) === d.group_id) {
          setSystemAction(d.data.msg);
        }
      }
      console.log(d);
    };

    const payOk = () => {
      // 保证金支付成功
      showPayBondMoney.value = false;
      checkAlbumBondPayAction();
    };
    const payClear = () => {
      // 取消支付保证金
      showPayBondMoney.value = false;
    };

    const goRecharge = () => {
      router.push("/users/recharge");
    };

    let bidLock = false;
    const showBidPrice = ref(1000); // 展示要出的金额
    const showBidEL = ref(false); // 确认弹窗
    const kong = () => {};
    const clearBidAction = () => {
      // 取消出价
      showBidEL.value = false;
      showBidPrice.value = "";
    };
    const bidOkAction = () => {
      if (bidLock) return false;
      bidLock = true;
      const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
      const auction_id = auctionData.value.product_id;

      auctionBidPrice({
        bid_price: money_bid,
        auction_id: auction_id,
        is_anonymous: is_anonymous.value,
      })
        .then(() => {
          clearBidAction();
          bidLock = false;
        })
        .catch((message) => {
          clearBidAction();
          Toast(message);
          bidLock = false;
        });
    };
    const bidAction = () => {
      // 出价
      if (auctionData.value.auction_status === 0 || auctionData.value.auction_status === 2) {
        Toast("拍品还未开始");
        return false;
      } else if (auctionData.value.auction_status === 1) {
        const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
        if (!showBidEL.value) {
          showBidPrice.value = money_bid;
        }
        if (auctionData.value.auction_type == 3) {
          is_anonymous.value = 1;
          bidOkAction();
        } else if (!showBidEL.value) {
          showBidEL.value = true;
          return false;
        }
      }
    };
    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });

    const showListAction = () => {
      auctionList.value.changeStatue = 1;
      auctionList.value.finished = false;
      auctionList.value.loading = false;
      auctionList.value.list = [];
      auctionList.value.queryParams.album_id = albumData.value.album_id;
      auctionList.value.queryParams.page = 1;
      auctionList.value.total = 0;
      auctionList.value.show = true;
      auctionList.value.loadList();
      auctionList.value.loadAlbumData(albumData.value.album_id);
      // 查看拍品列表
    };
    const showDetail = (id) => {
      router.push("/pages/auctionDetail/index?id=" + id);
    };

    const onFinish = () => {
      if (auctionData.value.now_price) {
        auctionData.value.auction_status = 5;
      } else {
        auctionData.value.auction_status = 4;
      }
    };
    const showDetailAction = () => {
      if (auctionDetailEL.value) {
        auctionDetailEL.value.loadInit(auctionData.value.product_id);
      }
    };
    // 获取用户名
    const uname = computed(() => {
      return store.getters.uname;
    });
    // 获取用户头像
    const avatar = computed(() => {
      return store.getters.avatar;
    });

    const is_anonymous = ref(1);
    const changeAnonymous = () => {
      if (userLastBidRecord.value) {
        Toast(
          `该拍品必须${
            userLastBidRecord.value.is_anonymous == 1 ? "匿名" : "实名"
          }出价`
        );
        is_anonymous.value = userLastBidRecord.value.is_anonymous;
      } else if (compel_anonymous.value === 1) {
        Toast(`该场必须匿名出价`);
        is_anonymous.value = 1;
      }
    };

    const goOrderAction = () => {
      router.push("/order/list?status=1");
    };

    const orderWaitPay = ref(0);
    const loadCount = () => {
      // userCenterNum({
      //   data: '[{"key":"0-0","type":"order_wait_pay","desc":""}]',
      // }).then((res) => {
      //   orderWaitPay.value = res.data[0].value;
      // });
      orderData().then(res=>{
        orderWaitPay.value = res.data.noPay;
      })
    };

    const userLastBidRecord = ref(null);
    const loadLastUserBidRecord = () => {
      if(auctionData.value.product_id){
        getLastUserBidRecord({ auction_id: auctionData.value.product_id }).then(
          (res) => {
            if (res.data && res.data.bid_record) {
              is_anonymous.value = res.data.bid_record.is_anonymous;
              userLastBidRecord.value = res.data.bid_record;
            } else {
              is_anonymous.value = 1;
            }
          }
        );
      }
    };

    const setSystemAction = (message, key) => {
      const commentData = {
        msg_type: key == 1 ? "ht" : "system",
        content: message,
      };
      commentEL.value.sockedMes({ type: "send_msg_new", data: commentData });
    };

    const hideAllAction = () => {
      content.emit("hideAll");
    };

    const hideAction = () => {
      if (hideAll.value) {
        content.emit("hideAll");
      }
    };

    return {
      roomId,
      albumId,
      albumData,
      auctionData,
      bidPriceList,
      priceIndex,
      isPayBond,
      userNumber,
      userNumberType,
      vanSwiper,
      vanSwiperBid,
      formatPriceNumber,
      canBidPrice,
      rightBtnStyle,
      showPayBondMoney,
      account,
      colorData,
      commentEL,
      loginNotiveEL,
      uid,
      uname,
      avatar,
      auctionList,
      auctionDetailEL,
      showBidPrice,
      showBidEL,
      is_anonymous,
      compel_anonymous,
      merchantInfo,
      merchantid,
      chujiaJsonFlag,
      chujiaJson,
      orderWaitPay,
      parseTime,
      formatDateNumber,
      CongratulationsEL,
      CongratulationsMyEL,

      loadAlbumDetail,
      loadNowAuction,
      sockedMes,
      jiaAction,
      jianAction,
      useNumberPlateAction,
      payBondAction,
      payOk,
      payClear,
      loadMsgAction,
      goRecharge,
      bidAction,
      showListAction,
      showDetail,
      onFinish,
      showDetailAction,
      bidOkAction,
      clearBidAction,
      kong,
      changeAnonymous,
      goOrderAction,
      loadCount,
      hideAllAction,
      hideAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.left-bottom {
  position: fixed;
  left: 0;
  width: 624px;
  bottom: 162px;
  z-index: 1000;
  transition: opacity 0.5s;

  .hide-view {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    opacity: 0;
  }

  .btn {
    height: 64px;
    width: 440px;
    text-align: center;
    line-height: 64px;
  }

  .bid-view {
    .bid-view-top {
      color: #fff;
      font-size: 20px;

      .flex-one {
        margin-left: 10px;
      }

      .r {
        width: 96px;
        height: 36px;
        border-radius: 21px;
        border: 2px solid #ffffff;
        text-align: center;
        line-height: 36px;
      }
    }

    .bid-view-bottom {
      box-sizing: border-box;
      height: 72px;
      margin-top: 20px;
      .jia,
      .jian {
        width: 72px;
        height: 72px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        border: 2px solid rgba(255, 255, 255, 0.25);
        box-sizing: border-box;

        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }

      .num {
        text-align: center;
        height: 72px;
        margin: 0 18px;
        line-height: 72px;
        background: #d5303e;
        font-size: 28px;
        font-weight: 500;
        border-radius: 8px;
        color: #fff;
        box-sizing: border-box;

        .bid-price-list {
          height: 72px;
          margin-left: 8px;

          .bid-p {
            height: 72px;
            line-height: 72px;
          }
        }
      }
    }
  }
}

.btn-icon {
  position: fixed;
  left: 20px;
  bottom: 68px;
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  z-index: 100;
  border-radius: 45px;
  img {
    width: 40px;
    height: 40px;
    display: block;
  }
}

.btn-icon1 {
  left: 100px;
}

.btn-icon .tag-view {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
}

.btn-icon .tag {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  font-size: 20px;
  padding: 0 10px;
  min-width: 30px;
}

.confirm-bid {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.confirm-bid .confirm-bid-content {
  background: rgba(0, 0, 0, 0.7);
  width: 560px;
  margin-top: 400px;
  border-radius: 16px;
}
.confirm-bid .confirm-bid-content .confirm-bid-user {
  margin-top: 30px;
  margin-left: 32px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}
.confirm-bid .confirm-bid-content .confirm-bid-user img {
  width: 48px;
  height: 48px;
  display: block;
  margin-right: 12px;
  border-radius: 50%;
}
.confirm-bid .confirm-bid-content .confirm-bid-price {
  font-size: 56px;
  font-family: NUM;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 26px;
}
.confirm-bid-number {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  margin-top: 40px;
  padding: 0 84px;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns {
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  display: flex;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn {
  height: 80px;
  line-height: 80px;
  flex: 1;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #999;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn.q {
  color: red;
  border-left: 2px solid rgba(255, 255, 255, 0.05);
}

.all-bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.top-bg {
  width: 100%;
  padding: 0 20px;
  margin-left: -20px;
  filter: blur(15px);
}

.bottom-bg {
  width: 100%;
}

.top-bg img {
  width: 100%;
  height: 100%;
  display: block;
}

.bid-price-show {
  display: flex;
  margin-bottom: 20px;
}

.bid-price-show .div {
  height: 92px;
  background: linear-gradient(
    270deg,
    rgba(173, 140, 84, 0) 0%,
    rgba(173, 140, 84, 0.8) 100%
  );
  border-radius: 0px 200px 200px 0px;
  border: 2px solid rgba(255, 237, 213, 0.5);
  border-left: none;
  box-sizing: border-box;

  transform: translateX(-470px);
  transition: transform 1s;
}

.price-view {
  padding-left: 20px;
}

.price-now-avatar {
  width: 80px;
  height: 80px;
  margin: 6px 6px 6px 10px;
}

.price-now-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: block;
}

.price-now-name {
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  text-shadow: 0px 2px 0px rgba(193, 149, 92, 0.5);
  font-size: 22px;
  text-align: right;
  line-height: 32px;
}

.price-now-price {
  height: 42px;
  font-size: 30px;
  font-weight: 600;
  color: #fff298;
  line-height: 42px;
  text-shadow: 0px 2px 0px rgba(193, 149, 92, 0.5);
  text-align: right;
}

.album-bid-view {
  margin-top: 20px;
  width: 600px;
  padding-left: 20px;
}

.album-bid-view .btn {
  height: 72px;
  width: 600px;
  text-align: center;
  line-height: 72px;
  background: #d5303e;
  font-size: 28px;
  font-weight: 500;
  border-radius: 12px;
  color: #fff;
}

.number-info {
  width: 600px;
  height: 52px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  padding: 8px 0 8px 14px;
}

.number-info .bid-view-top {
  flex: 1;
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
}

.number-info .bid-view-top .flex-one {
  margin-left: 10px;
}

.number-info .bid-view-top .r {
  height: 36px;
  border-radius: 21px;
  text-align: center;
  line-height: 36px;
}

.number-info .bid-view-top .r img {
  width: 32px;
  height: 32px;
  display: block;
}

.auction-info {
  position: fixed;
  bottom: 328px;
  left: 20px;
  width: 598px;
  height: 96px;
  background: linear-gradient(
    90deg,
    rgba(255, 245, 227, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 2px solid #fff5e2;
  z-index: 1000;
  box-sizing: border-box;
  padding: 8px 20px 8px 8px;
  transition: bottom 0.5s;

  .auction-info-top .auction-info-status {
    width: 90px;
    height: 36px;
    background: #d5303e;
    border-radius: 18px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
  }

  .auction-info-top .auction-info-name {
    flex: 1;
    margin-left: 8px;
    font-size: 26px;
    font-weight: 500;
    color: #8b6244;
  }

  .auction-info-top .auction-num {
    margin-left: 48px;
    font-size: 20px;
    font-weight: 500;
    color: #8b6244;
  }

  .auction-info-bottom {
    margin-top: 6px;
    height: 36px;
  }

  .auction-info-user .user-avatar {
    width: 36px;
    height: 36px;
    display: block;
  }

  .auction-info-user .user-avatar img {
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 50%;
  }

  .auction-info-user .user-name {
    margin-left: 8px;
    font-size: 24px;
    font-weight: 500;
    color: #8b6244;
    line-height: 36px;
    max-width: 110px;
  }

  .auction-info-price {
    margin-left: 8px;
    font-size: 32px;
    font-weight: 600;
    color: #d5303e;
    position: relative;
    bottom: 2px;
  }

  .auction-info-bottom .is-confirm {
    font-size: 20px;
    font-weight: 500;
    color: #fb7575;
    margin-left: 8px;
  }

  .auction-info-bottom .is-wait {
    font-size: 20px;
    font-weight: 500;
    color: #c1955c;
    margin-left: 8px;
  }

  .auction-count {
    font-weight: 600;
    color: #c1955c;
  }
}
.color-h {
  font-size: 22px;
  color: #c1955c;
}
.jjjp {
  color: #d5303e;
}

.hide {
  opacity: 0;
}
</style>
