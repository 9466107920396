<template>
  <div class="home" style="height:100vh;">
    <div class="live-title flex align-items-c">
      <div
        class="back-img item-flex-center"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220413/627ba986f74257cafe5196b2adad1a5b.png"
          alt=""
        >
      </div>
      <div class="title-right flex-one flex align-items-c">
        <div class="live-name line1 ft14 fw500">{{ mer_name }}</div>
        <div class="live-number ml10"><span>{{ liveData.click_num }}人观看</span></div>
      </div>
    </div>

    <div
      v-if="liveData.live_status == 1 && liveStatue != 1"
      class="no-start-page item-flex-center"
    >
      <div class="live-name">{{liveData.live_title}}</div>
      <div class="live-img">
        <van-image
          class="img"
          v-if="liveData.cover_image"
          :src="liveData.cover_image + '!m640'"
          fit="contain"
        ></van-image>
      </div>
      <div class="live-time">{{ parseTime(liveData.start_time, '{y}-{m}-{d} {h}:{i}') }}开播</div>
      <div
        class="live-start-btn"
        @click="likeLiveAction"
      >{{ liveData.isCare ? '取消提醒' : '开播提醒' }}</div>
    </div>

    <div
      class="live"
      :style="liveData.extend_data && liveData.extend_data.live_screen_direction == 2 ? 'height: ' + (422 / 75) + 'rem;padding-top: ' + (150 / 75) + 'rem;': ''"
      @click="hideAllAction"
    >
      <template v-if="liveData">
        <div
          v-if="liveData.live_status == 1 && liveStatue != 1"
          class="txt h"
          :style="liveData.extend_data && liveData.extend_data.live_screen_direction == 1 ? 'padding-top: ' + (100 / 75) + 'rem;' : ''"
        >主播还未开始直播</div>
        <template v-else-if="liveData.live_status == 3">
          <div
            v-if="liveData.videoRecord && liveData.videoRecord.length > 0"
            :class="{ 'hengping': live_screen_direction == 2 }"
            @click.stop="kong"
          >
            <div
              id="id_test_video"
              ref="tcplayer"
            ></div>
            <div
              class="play-icon"
              @click="playAction"
              v-if="liveStatue == 0"
            >
              <img src="https://img.artart.cn/node/2020/38/1583600791211370936.png">
            </div>
          </div>
          <div
            v-else
            class="txt h"
            :style="liveData.extend_data && liveData.extend_data.live_screen_direction == 1 ? 'padding-top: ' + (100 / 75) + 'rem;' : ''"
          >稍后查看回放</div>
        </template>
        <div
          v-else-if="liveStatue == -1"
          class="txt h"
          :style="liveData.extend_data && liveData.extend_data.live_screen_direction == 1 ? 'padding-top: ' + (100 / 75) + 'rem;' : ''"
        >主播暂时离开，请稍后</div>
        <template v-else-if="liveData.live_status == 2">
          <div :class="{ 'hengping': live_screen_direction == 2 }">
            <div
              id="id_test_video"
              ref="tcplayer"
            ></div>
            <div
              class="play-icon"
              @click="playAction"
              v-if="liveStatue == 0"
            >
              <img src="https://img.artart.cn/node/2020/38/1583600791211370936.png">
            </div>
          </div>
        </template>
      </template>
    </div>
    <div
      class="live-bottom flex align-items-c"
      :class="{ 'jz': liveData.live_type == 'auction_album', 'hide': hideAll }"
    >
      <div
        class="hide-view"
        v-show="hideAll"
        @click.stop="hideAllAction"
      ></div>
      <div class="search flex-one flex align-items-c">
        <input
          v-model="speakVal"
          class="colorWrite"
          type="text"
          placeholder="说点什么..."
          @blur="blurinput"
          @keydown.enter="sayAction"
        >
      </div>
      <div class="view share item-flex-center">
        <img
          src="https://artimg2.artart.cn/image/20220413/64b84fa1d5a4b02624d569b7f6648834.png"
          alt=""
        >
      </div>
      <div
        class="view share item-flex-center"
        @click="shareAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220413/f0d2bccf5bb9812c561c0285a23e8ead.png"
          alt=""
        >
      </div>
      <div
        class="view like item-flex-center"
        @click="clickLikeAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220413/11115a85553e799a4839c125274d5ed6.png"
          alt=""
        >
        <div
          v-show="like_num"
          class="tag-view item-flex-center"
        >
          <div class="tag">{{ like_num }}</div>
        </div>
      </div>
    </div>
    <Auction
      v-if="liveData.live_type == 'auction_album'"
      ref="auctionEL"
      @hideAll="hideAllAction"
      :hideAll="hideAll"
      :merchantid="merchant_id"
      :merchant="merchant"
    />
    <News
      v-if="liveData.live_type == 'news'"
      ref="newsEL"
      @hideAll="hideAllAction"
      :hideAll="hideAll"
    />
    <div
      v-if="viewList.length > 0 && !hideAll"
      class="like-view"
      @tap.stop="kong"
    >
      <div
        class="animate"
        :key="index"
        v-for="(item,index) in viewList"
        :class="`animate${index%3}`"
      >
        <img
          style="width:100%;height: 100%;"
          :src="item"
        >
      </div>
    </div>

    <div
      class="no-start-page"
      v-if="isEnd && endData"
    >
      <div class="live-top-kong"></div>
      <div class="live-name">{{liveData.live_title}}</div>
      <div class="live-img">
        <van-image
          class="img"
          v-if="liveData.cover_image"
          :src="liveData.cover_image + '!m640'"
          fit="contain"
        ></van-image>
      </div>
      <div class="live-status-end item-flex-center">
        <div class="h"></div>
        <div class="v">直播已结束</div>
        <div class="h"></div>
      </div>
      <div
        class="live-time1"
        v-if="endData.live_type == 'auction_album' && endData.album_info && endData.album_info.album_status == 3"
      >{{ parseTime(endData.album_info.album_end_time, '{y}-{m}-{d} {h}:{i}') }}结拍</div>
      <div
        class="live-end-btn flex align-items-c"
        v-if="endData.live_type == 'auction_album' && endData.album_info && endData.album_info.album_status == 3"
      >
        <div class="item flex-one">
          <div class="n">{{ endData.album_info.deal_num }}/{{ endData.album_info.total_num }}</div>
          <div class="t">成交/拍品(件)</div>
        </div>
        <div class="item flex-one">
          <div class="n">{{ endData.album_info.deal_price_format }}</div>
          <div class="t">成交金额(元)</div>
        </div>
        <div class="item flex-one">
          <div class="n">{{ endData.album_info.deal_rate }}%</div>
          <div class="t">成交率</div>
        </div>
      </div>
      <div class="live-end-btn flex align-items-c">
        <div class="item flex-one">
          <div class="n">{{ endData.timeDuration }}</div>
          <div class="t">直播时长</div>
        </div>
        <div class="item flex-one">
          <div class="n">{{ endData.click_num_uv }}</div>
          <div class="t">观看人数</div>
        </div>
        <div class="item flex-one">
          <div class="n">{{ endData.like_num }}</div>
          <div class="t">点赞</div>
        </div>
      </div>
    </div>
    <FollowQrcodeUrl
      :is-show="is_follow_official_account === false && qrcode_url"
    ></FollowQrcodeUrl>
  </div>
</template>

<script>
import Auction from "./modules/Auction.vue";
import News from "./modules/News.vue";
import { useStore } from "vuex";
import {
  liveDetail,
  loginSocketApi,
  joinRoomApi,
  sendChatMsg,
  clickLike,
} from "@/api/live";
import { ref, onBeforeMount, reactive, computed, nextTick, watch } from "vue";
import { usePageVisibility } from '@vant/use';
import { useRoute, useRouter } from "vue-router";
import { TcPlayer } from "../../assets/js/TcPlayer-module-2.4.1";
import { Toast, ImagePreview, Image as VanImage } from "vant";
import webSocket from "@/utils/webSocket";
import { sharePosterImg } from "@/api/system";
import { parseTime, formatDateNumber } from "@/utils/util";
import { relationCreate, relationDel } from "@/api/user";
import { shareConfig } from '@/utils/share';
import FollowQrcodeUrl from "@/components/FollowQrcodeUrl";

export default {
  components: {
    Auction,
    News,
    [VanImage.name]: VanImage,
    FollowQrcodeUrl
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const id = ref(""); // 直播id
    const albumId = ref(""); // 拍卖专场id
    const auctionEL = ref(null);
    const newsEL = ref(null);
    const liveData = ref({});
    const liveStatue = ref(0);
    const sdata = reactive({
      socketServe: webSocket.Instance,
    });
    const client_id = ref(""); // socket连接标识
    // 获取用户可出价额度
    // const mer_name = computed(() => {
    //   return store.getters.mer_name;
    // });
    let mer_name = ref("");
    let merchant = ref({});
    const nickname = computed(() => {
      return store.getters.uname;
    });
    const pageVisibility = usePageVisibility();

    watch(pageVisibility, (value) => {
      if (value == 'visible' && store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
    });
    const merchant_id = computed(() => {
      return route.query.merchantid
    });
    const videoIndex = ref(0);

    const live_screen_direction = ref(""); // 横屏 2 竖屏 1

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      store.dispatch("user/getAccountInfo");
      if (store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
      id.value = route.query.id;

      nextTick(() => {
        liveDetail(id.value, {merchant_id: route.query.merchantid}).then((res) => {
          if(res.data.merchant && res.data.merchant.mer_name){
            mer_name.value = res.data.merchant.mer_name;
            merchant.value = res.data.merchant;
          }
          liveData.value = res.data;
          shareConfig(
            res.data.live_title,
            `${ parseTime(res.data.start_time, '{y}-{m}-{d} {h}:{i}') }开播`,
            res.data.cover_image,
            "",
            "",
            location.href
          );
          nextTick(() => {
            // 赋值给组件
            if (res.data.live_type == "auction_album") {
              auctionEL.value.roomId = id.value;
              auctionEL.value.albumId = res.data.relation_id;
              auctionEL.value.loadAlbumDetail();
              auctionEL.value.loadMsgAction(id.value);
            } else if (res.data.live_type == "news") {
              newsEL.value.roomId = id.value;
              newsEL.value.loadMsgAction(id.value);
            }
          });
          like_num.value = res.data.like_num;

          live_screen_direction.value =
            res.data.extend_data.live_screen_direction;

          liveStatue.value = res.data.stream_state == 1 ? 0 : -1;
          if (liveData.value.live_status == 2) {
            if (res.data.stream_state == 1) {
              setTimeout(() => {
                playAction();
              }, 100);
            }
          } else if (liveData.value.live_status == 3) {
            setTimeout(() => {
              if (
                liveData.value.videoRecord &&
                liveData.value.videoRecord.length > 0
              ) {
                videoIndex.value = 0;
                playAction("1");
              }
            }, 100);
          }
        });

        if (!webSocket.Instance.ws) {
          webSocket.Instance.connect();
          sdata.socketServe = webSocket.Instance;
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          sdata.socketServe.registerCallBack("openSocket", () => {
            // sendData({
            //   type: "join_auction_room",
            //   auction_id: id.value,
            // });
          });
        } else if (client_id.value || webSocket.Instance.client_id) {
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          joinRoom();
          // sendData({
          //   type: "join_auction_room",
          //   auction_id: id.value,
          // });
        }
      });
    });
    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });
    let loadAnm = false;
    let oldNum = 0;
    const endData = ref(null);
    const isEnd = ref(true);

    const sockedMes = (d) => {
      if (d.type == "init") {
        client_id.value = d.client_id;
        webSocket.Instance.client_id = d.client_id;
        loginSocket();
      } else if (d.group_id != id.value) {
        console.log("is none");
      } else if (d.type == "live_end") {
        if (player) {
          player.pause();
          isPlay.value = true;
        }
        const data = d.data;
        const lag = d.data.time_duration;
        const second = formatDateNumber(Math.floor(lag % 60));
        let minite = formatDateNumber(Math.floor((lag / 60) % 60));
        let hour = formatDateNumber(Math.floor(lag / 3600));

        data.timeDuration = `${hour}:${minite}:${second}`;
        endData.value = data;
        isEnd.value = true;
      } else if (d.type == "stream_state") {
        if (d.group_id == id.value) {
          if (d.data.state === 1) {
            if (liveData.value.live_status == 2) {
              playAction();
            }
          } else {
            if (player && player.playing()) {
              player.pause();
              isPlay.value = false;
              return false;
            }
            liveStatue.value = -1;
          }
        }
      } else if (d.type === "click_like") {
        if (d.data.uid != uid.value) {
          if (loadAnm) {
            oldNum += Number(d.data.num);
            return false;
          }
          loadAnm = true;
          let n = oldNum + Number(d.data.num);
          oldNum = 0;
          let num = n > 10 ? 10 : n;
          like_num.value += n;

          for (let i = 0; i < num; i++) {
            likeTimeout1 = setTimeout(() => {
              loadLike();
              if (i == num - 1) {
                loadAnm = false;
                if (likeTimeout1) clearTimeout(likeTimeout1);
                likeTimeout1 = setTimeout(() => {
                  if (!likeTimeout) {
                    viewList.value = [];
                  }
                  likeTimeout1 = null;
                }, 1000);
              }
            }, i * 200);
          }
        }
      } else {
        if (auctionEL.value) {
          auctionEL.value.sockedMes(d);
        } else if (newsEL.value) {
          newsEL.value.sockedMes(d);
        }
      }
    };

    const loginSocket = () => {
      loginSocketApi({
        client_id: client_id.value,
      })
        .then(() => {
          joinRoom();
        })
        .catch((message) => Toast(message));
    };

    const joinRoom = () => {
      joinRoomApi({
        client_id: client_id.value || webSocket.Instance.client_id,
        room_type: "live",
        room_id: id.value,
      })
        .then()
        .catch((message) => Toast(message));
    };

    let player = null;
    const isPlay = ref(false);

    const loadVideo = (hf) => {
      let data = {};
      if (hf) {
        data = {
          m3u8: liveData.value.videoRecord[videoIndex.value].video.MediaUrl,
        };
      } else {
        data = {
          webrtc: liveData.value.play_url.webrtc,
          m3u8: liveData.value.play_url.hls,
          flv: liveData.value.play_url.flv,
        };
      }
      // liveData.value.videoRecord
      const w = document.documentElement.clientWidth;
      let h = document.documentElement.clientHeight;
      if (live_screen_direction.value == 2) {
        h = (w / 16) * 9;
      }
      player = new TcPlayer("id_test_video", {
        ...data,
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: {
          style: "cover",
          src: liveData.value.cover_image,
        },
        width: w,
        height: h,
        live: !hf,
        controls: !hf ? "none" : "default",
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        listener: (d) => {
          if (d.type == "loadeddata") {
            const video = document.getElementsByTagName("video");
            if (video && video[0]) {
              video[0].style.objectFit = "cover";
            }
          } else if (d.type == "error") {
            liveStatue.value = -1;
          } else if (d.type == "play") {
            liveStatue.value = 1;
            isPlay.value = true;
          } else if (d.type == "ended") {
            if (hf) {
              if (liveData.value.videoRecord.length - 1 > videoIndex.value) {
                videoIndex.value += 1;
              } else {
                videoIndex.value = 0;
              }
              const m =
                liveData.value.videoRecord[videoIndex.value].video.MediaUrl;
              player.load(m);
            }
          }
        },
      });
    };
    const playAction = (hf) => {
      if (player) {
        player.play();
        isPlay.value = true;
        return false;
      }
      liveStatue.value = 0;
      setTimeout(() => {
        loadVideo(hf);
      }, 100);
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const speakVal = ref("");
    const sayAction = () => {
      // 发言
      if (speakVal.value) {
        sendChatMsg({
          live_id: id.value,
          msg_type: "text",
          content: speakVal.value,
        })
          .then(() => {
            speakVal.value = "";
          })
          .catch((message) => Toast(message));
      }
    };
    let likeTimeout = null;
    let likeTimeout1 = null;
    let likeNum = 0;
    const like_num = ref(0);
    const clickLikeAction = () => {
      loadLike();
      if (likeTimeout) clearTimeout(likeTimeout);
      likeNum += 1;
      likeTimeout = setTimeout(() => {
        likeAction(likeNum);

        if (!likeTimeout1) {
          viewList.value = [];
        }

        likeNum = 0;
        likeTimeout = null;
      }, 1000);
    };
    const likeAction = (num) => {
      like_num.value += num;
      clickLike({ live_id: id.value, num: num });
    };
    const viewList = ref([]); // 点赞列表
    const loadLike = () => {
      let arr = [
        "https://artimg2.artart.cn/image/20210602/f915d7a7bd28f9b7c795dcebe2f26526.png",
        "https://artimg2.artart.cn/image/20210602/7d9c4fbb4874a2684be4df0723552c29.png",
        "https://artimg2.artart.cn/image/20210602/54202b9f41a0d6ffac8cc2c19bdd5322.png",
        "https://artimg2.artart.cn/image/20210602/369c052518e9e34d154f101b08e0b934.png",
        "https://artimg2.artart.cn/image/20210603/e552e4a1fc92318c97cc3ba900ddcb0f.png",
        "https://artimg2.artart.cn/image/20210603/e552e4a1fc92318c97cc3ba900ddcb0f.png",
      ];
      viewList.value.push(arr[Math.floor(Math.random() * arr.length)]);
    };

    const hideAll = ref(false);
    const hideAllAction = () => {
      hideAll.value = !hideAll.value;
    };

    const shareAction = () => {
      console.log(store.getters);
      const data = {
        poster_type: "liveRoom",
        qrcode_type: 2,
        Independent_qrcode: 0,
        id: id.value,
        return_format: 1,
        inviteuid: store.getters.uid,
      };
      ImagePreview([sharePosterImg(data)]);
    };

    const kong = () => {};

    const likeLiveAction = () => {
      let action = relationCreate;
      const data = {
        type_id: id.value,
        type_ids: id.value,
        type: 13,
      };
      if (liveData.value.isCare) {
        action = relationDel;
      }
      action(data)
        .then(() => {
          liveData.value.isCare = liveData.value.isCare == 1 ? 0 : 1;
        })
        .catch((data) => Toast(data.message));
    };
    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });
    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });
    const blurinput = () => {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    };
    return {
      liveData,
      isPlay,
      liveStatue,
      albumId,
      auctionEL,
      newsEL,
      mer_name,
      merchant,
      merchant_id,
      speakVal,
      nickname,
      live_screen_direction,
      viewList,
      like_num,
      hideAll,
      parseTime,
      endData,
      isEnd,
      is_follow_official_account,
      qrcode_url,

      playAction,
      backAction,
      sayAction,
      clickLikeAction,
      hideAllAction,
      shareAction,
      kong,
      likeLiveAction,
      blurinput
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
  overflow: hidden;
  background: url(https://artimg2.artart.cn/image/20220414/cb39b67cf8c2c276f51a4df10e62c797.png)
    no-repeat center;
  background-size: cover;

  .live-title {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 88px;
    z-index: 5001;

    .back-img {
      margin-left: 32px;
      height: 88px;

      img {
        width: 48px;
        height: 48px;
        display: block;
        margin-right: 10px;
      }
    }

    .title-right {
      color: #fff;
      overflow: hidden;
      max-width: 450px;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);

      .live-number {
        font-size: 20px;
      }
    }
  }

  .live {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;

    .hengping {
      margin-top: 88px;
      height: 422px;
    }

    .txt {
      position: absolute;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 500;
      color: #fff;
      z-index: 2;

      &.h {
        height: 422px;
      }
    }

    .live-dialog {
      position: absolute;
      right: 24px;
      bottom: 18px;
      z-index: 100;

      div {
        margin-left: 8px;
        width: 76px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .ft11 {
    font-size: 22px;
  }
}

.play-icon {
  position: absolute;
  margin-left: -35px;
  margin-top: -35px;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  z-index: 1000;
}

.play-icon image {
  width: 150px;
  height: 150px;
  display: block;
}

.live-bottom {
  position: fixed;
  left: 20px;
  right: 32px;
  bottom: 68px;
  z-index: 100;

  &.jz {
    left: 180px;
  }

  .search {
    height: 64px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 32px;
    border: 2px solid rgba(255, 255, 255, 0.25);
    padding: 0 20px;
    box-sizing: border-box;

    input {
      background: none;
      border: none;
      width: 100%;
      color: #fff;
      font-size: 24px;
      &::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }

  .share,
  .like {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 45px;
    margin-left: 16px;
    position: relative;

    img {
      width: 40px;
      height: 40px;
      display: block;
    }
  }
  .view {
    width: 64px;
    height: 64px;
    border-radius: 45px;
    margin-left: 16px;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.25);
    box-sizing: border-box;

    .tag-view {
      position: absolute;
      top: -30px;
      left: -10px;
      right: -10px;
    }

    .tag {
      height: 70px;
      text-align: center;
      border-radius: 15px;
      padding: 0 10px;
      min-width: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
      text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
    }
  }
}

.like-view {
  width: 150px;
  height: 500px;
  position: fixed;
  right: -16px;
  bottom: 130px;
  z-index: 100;
  overflow: visible;
}

.animate {
  position: absolute;
  font-size: 30px;
  color: white;
  text-align: center;
  line-height: 50px;
  width: 80px;
  transform: translateY(0);
  z-index: 10;
  left: 75px;
  bottom: 0;
}

.animate0 {
  opacity: 0;
  animation: mymove 1.8s 1;
}

.animate1 {
  opacity: 0;
  animation: mymove1 1.8s 1;
}

.animate2 {
  opacity: 0;
  animation: mymove2 1.8s 1;
}

@keyframes mymove {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 75px;
  }
}

@keyframes mymove0 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 75px;
  }
}

@keyframes mymove1 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 0px;
  }
}

@keyframes mymove2 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 90px;
  }
}

.hide {
  opacity: 0;
}
.hide-view {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  opacity: 0;
}

.no-start-page {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5000;
  background: url(https://artimg2.artart.cn/image/20220414/cb39b67cf8c2c276f51a4df10e62c797.png)
    no-repeat center;
  background-size: cover;
  flex-direction: column;
}

.no-start-page .live-top-kong {
  height: 220px;
}

.no-start-page .live-name {
  width: 100%;
  padding: 0 30px;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  line-height: 44px;
  box-sizing: border-box;
  text-align: center;
}

.no-start-page .live-img {
  padding: 0 30px;
  width: 100%;
  height: 300px;
  margin-top: 36px;
  box-sizing: border-box;
}

.no-start-page .live-img .img {
  width: 100%;
  height: 100%;
  display: block;
}

.no-start-page .live-status-end {
  margin-top: 48px;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  line-height: 44px;
}

.no-start-page .live-status-end .h {
  width: 60px;
  height: 2px;
  background: #fff;
  margin: 0 10px;
}

.no-start-page .live-time {
  padding: 0 30px;
  width: 100%;
  margin-top: 64px;
  box-sizing: border-box;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
}

.no-start-page .live-time1 {
  padding: 0 30px;
  width: 100%;
  margin-top: 48px;
  box-sizing: border-box;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
}

.no-start-page .live-start-btn {
  width: 192px;
  height: 64px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  border: 2px solid #979797;
  line-height: 60px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  box-sizing: border-box;
  margin-top: 30px;
}

.no-start-page .live-end-btn {
  margin-top: 52px;
  padding: 0 40px;
}

.no-start-page .live-end-btn .item {
  text-align: center;
  color: #fff;
}

.no-start-page .live-end-btn .item .n {
  font-size: 32px;
  color: #fff;
  font-weight: 600;
}

.no-start-page .live-end-btn .item .t {
  font-size: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
</style>
