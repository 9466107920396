<template>
  <div>
    <template v-if="transaction">
      <div class="confirm-background"></div>
      <div class="confirm">
        <div class="confirm-view flex align-items-c justify-c">
          <div class="confirm-content fw500">
            <img
              class="confirm-bg"
              src="https://artimg2.artart.cn/image/20220318/c43dff725ab18c95cc0510ab4a392e7c.png"
            >
            <div class="t1 p">中拍{{transaction.lot}}号拍品</div>
            <div class="t2 p">成交价{{transaction.transactionPrice}}</div>
            <div class="t3 p">落槌价{{transaction.price}}</div>
            <div class="t4 p">{{ merchantInfo.mer_name }}</div>
            <div class="t5 p">
              <van-image
                class="img"
                :src="merchantInfo.mer_logo + '!m640'"
                fit="contain"
              ></van-image>
            </div>
            <div
              class="btn1 p"
              @click="closeCongratulationsMyAction"
            ></div>
            <div
              class="btn2 p"
              @click="goPay"
            >去支付</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const transaction = ref(null);
    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const closeCongratulationsMyAction = () => {
      transaction.value = null;
    }

    const goPay = () => {
      router.push("/pages/orderConfirm/index?order_id=" + this.transaction.order_id);
    }

    return {
      transaction,
      merchantInfo,

      closeCongratulationsMyAction,
      goPay,
    };
  }
};
</script>

<style lang="scss" scoped>
.confirm {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  animation: warn 1s;
}

.confirm-background {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.confirm-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-content {
  width: 622px;
  height: 732px;
  position: relative;
  font-weight: 500;
}

.confirm-content .confirm-bg {
  width: 622px;
  height: 732px;
}

.confirm-content .p {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.confirm-content .t1 {
  top: 274px;
  height: 44px;
  line-height: 44px;
  color: #FFF6D1;
  font-size: 32px;
  font-weight: 500;
}

.confirm-content .t2 {
  top: 354px;
  height: 56px;
  line-height: 56px;
  color: #B65535;
  font-size: 40px;
}

.confirm-content .t3 {
  top: 418px;
  height: 34px;
  line-height: 34px;
  color: #B65535;
  font-size: 24px;
}

.confirm-content .t4 {
  top: 476px;
  height: 34px;
  line-height: 34px;
  color: #B65535;
  font-size: 24px;
}

.confirm-content .t5 {
  top: 526px;
  height: 60px;
  line-height: 60px;
  color: #b65535;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-content .t5 .img {
  width: 240px;
  height: 60px;
  display: block;
}

.confirm-content .btn1 {
  bottom: 0;
  height: 100px;
}

.confirm-content .btn2 {
  bottom: -50px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 28px;
}

@keyframes warn {
  0% {
    transform: scale(0);
    display: none;
    opacity: 0;
  }

  10% {
    transform: scale(0.1);
    display: block;
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
