<template>
  <div>
    <div
      class="left-bottom"
      :class="hideAll ? 'hide' : ''"
      @click="hideAllAction"
    >
      <div
        class="hide-view"
        v-if="hideAll"
        @click.stop="hideAction"
      ></div>
      <LoginNotive ref="loginNotiveEL" />
      <Comment
        ref="commentEL"
        isHigh="1"
      />
    </div>
  </div>
</template>

<script>
import Comment from "./Comment.vue";
import LoginNotive from "./LoginNotive.vue";
import { userCenterNum } from "@/api/user";
import { getConfigClassKeys } from "@/api/system";
import {
  formatPriceNumber,
  settingPrice,
  parseTime,
  formatDateNumber,
} from "@/utils/util";
import { Toast, Swipe, SwipeItem, CountDown, Switch } from "vant";
import { ref, computed, toRefs } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    LoginNotive,
    Comment,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [CountDown.name]: CountDown,
    [Switch.name]: Switch,
  },
  props: {
    hideAll: Boolean,
  },
  setup(props, content) {
    const { hideAll } = toRefs(props);
    const router = useRouter();
    const store = useStore();
    const roomId = ref(""); // 直播间id

    const loadMsgAction = (id) => {
      commentEL.value.loadMsg(id);
      loginNotiveEL.value.roomId = id;
    };

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const commentEL = ref(null); // 聊天列表组件
    const loginNotiveEL = ref(null); // 登录用户展示组件
    const sockedMes = (d) => {
      // 接收socket通知
      if (d.type === "send_msg" || d.type === "send_msg_new") {
        commentEL.value.sockedMes(d);
        return false;
      } else if (d.type === "join_live_room") {
        loginNotiveEL.value.sockedMes(d);
        return false;
      } else if (d.type === "auction_notice") {
        // 系统通知
        if (Number(roomId.value) === d.group_id) {
          setSystemAction(d.data.msg);
        }
      }
    };


    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });

    // 获取用户名
    const uname = computed(() => {
      return store.getters.uname;
    });
    // 获取用户头像
    const avatar = computed(() => {
      return store.getters.avatar;
    });

    const setSystemAction = (message, key) => {
      const commentData = {
        msg_type: key == 1 ? "ht" : "system",
        content: message,
      };
      commentEL.value.sockedMes({ type: "send_msg_new", data: commentData });
    };

    const hideAllAction = () => {
      content.emit("hideAll");
    };

    const hideAction = () => {
      if (hideAll.value) {
        content.emit("hideAll");
      }
    };

    return {
      roomId,
      formatPriceNumber,
      commentEL,
      loginNotiveEL,
      uid,
      uname,
      avatar,
      merchantInfo,
      parseTime,
      formatDateNumber,

      sockedMes,
      loadMsgAction,
      hideAllAction,
      hideAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.left-bottom {
  position: fixed;
  left: 0;
  width: 624px;
  bottom: 162px;
  z-index: 1000;
  transition: opacity 0.5s;

  .hide-view {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    opacity: 0;
  }

  .btn {
    height: 64px;
    width: 440px;
    text-align: center;
    line-height: 64px;
  }

  .bid-view {
    .bid-view-top {
      color: #fff;
      font-size: 20px;

      .flex-one {
        margin-left: 10px;
      }

      .r {
        width: 96px;
        height: 36px;
        border-radius: 21px;
        border: 2px solid #ffffff;
        text-align: center;
        line-height: 36px;
      }
    }

    .bid-view-bottom {
      box-sizing: border-box;
      height: 72px;
      margin-top: 20px;
      .jia,
      .jian {
        width: 72px;
        height: 72px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 12px;
        border: 2px solid rgba(255, 255, 255, 0.25);
        box-sizing: border-box;

        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }

      .num {
        text-align: center;
        height: 72px;
        margin: 0 18px;
        line-height: 72px;
        background: #d5303e;
        font-size: 28px;
        font-weight: 500;
        border-radius: 8px;
        color: #fff;
        box-sizing: border-box;

        .bid-price-list {
          height: 72px;
          margin-left: 8px;

          .bid-p {
            height: 72px;
            line-height: 72px;
          }
        }
      }
    }
  }
}

.btn-icon {
  position: fixed;
  left: 20px;
  bottom: 68px;
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  z-index: 100;
  border-radius: 45px;
  img {
    width: 40px;
    height: 40px;
    display: block;
  }
}

.btn-icon1 {
  left: 100px;
}

.btn-icon .tag-view {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
}

.btn-icon .tag {
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  font-size: 20px;
  padding: 0 10px;
  min-width: 30px;
}

.confirm-bid {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.confirm-bid .confirm-bid-content {
  background: rgba(0, 0, 0, 0.7);
  width: 560px;
  margin-top: 400px;
  border-radius: 16px;
}
.confirm-bid .confirm-bid-content .confirm-bid-user {
  margin-top: 30px;
  margin-left: 32px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}
.confirm-bid .confirm-bid-content .confirm-bid-user img {
  width: 48px;
  height: 48px;
  display: block;
  margin-right: 12px;
  border-radius: 50%;
}
.confirm-bid .confirm-bid-content .confirm-bid-price {
  font-size: 56px;
  font-family: NUM;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 26px;
}
.confirm-bid-number {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  margin-top: 40px;
  padding: 0 84px;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns {
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  display: flex;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn {
  height: 80px;
  line-height: 80px;
  flex: 1;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #999;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn.q {
  color: red;
  border-left: 2px solid rgba(255, 255, 255, 0.05);
}

.all-bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.top-bg {
  width: 100%;
  padding: 0 20px;
  margin-left: -20px;
  filter: blur(15px);
}

.bottom-bg {
  width: 100%;
}

.top-bg img {
  width: 100%;
  height: 100%;
  display: block;
}

.bid-price-show {
  display: flex;
  margin-bottom: 20px;
}

.bid-price-show .div {
  height: 92px;
  background: linear-gradient(
    270deg,
    rgba(173, 140, 84, 0) 0%,
    rgba(173, 140, 84, 0.8) 100%
  );
  border-radius: 0px 200px 200px 0px;
  border: 2px solid rgba(255, 237, 213, 0.5);
  border-left: none;
  box-sizing: border-box;

  transform: translateX(-470px);
  transition: transform 1s;
}

.price-view {
  padding-left: 20px;
}

.price-now-avatar {
  width: 80px;
  height: 80px;
  margin: 6px 6px 6px 10px;
}

.price-now-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: block;
}

.price-now-name {
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  text-shadow: 0px 2px 0px rgba(193, 149, 92, 0.5);
  font-size: 22px;
  text-align: right;
  line-height: 32px;
}

.price-now-price {
  height: 42px;
  font-size: 30px;
  font-weight: 600;
  color: #fff298;
  line-height: 42px;
  text-shadow: 0px 2px 0px rgba(193, 149, 92, 0.5);
  text-align: right;
}

.album-bid-view {
  margin-top: 20px;
  width: 600px;
  padding-left: 20px;
}

.album-bid-view .btn {
  height: 72px;
  width: 600px;
  text-align: center;
  line-height: 72px;
  background: #d5303e;
  font-size: 28px;
  font-weight: 500;
  border-radius: 12px;
  color: #fff;
}

.number-info {
  width: 600px;
  height: 52px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  padding: 8px 0 8px 14px;
}

.number-info .bid-view-top {
  flex: 1;
  color: #fff;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
}

.number-info .bid-view-top .flex-one {
  margin-left: 10px;
}

.number-info .bid-view-top .r {
  height: 36px;
  border-radius: 21px;
  text-align: center;
  line-height: 36px;
}

.number-info .bid-view-top .r img {
  width: 32px;
  height: 32px;
  display: block;
}

.auction-info {
  position: fixed;
  bottom: 328px;
  left: 20px;
  width: 598px;
  height: 96px;
  background: linear-gradient(
    90deg,
    rgba(255, 245, 227, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 2px solid #fff5e2;
  z-index: 1000;
  box-sizing: border-box;
  padding: 8px 20px 8px 8px;
  transition: bottom 0.5s;

  .auction-info-top .auction-info-status {
    width: 90px;
    height: 36px;
    background: #d5303e;
    border-radius: 18px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
  }

  .auction-info-top .auction-info-name {
    flex: 1;
    margin-left: 8px;
    font-size: 26px;
    font-weight: 500;
    color: #8b6244;
  }

  .auction-info-top .auction-num {
    margin-left: 48px;
    font-size: 20px;
    font-weight: 500;
    color: #8b6244;
  }

  .auction-info-bottom {
    margin-top: 6px;
    height: 36px;
  }

  .auction-info-user .user-avatar {
    width: 36px;
    height: 36px;
    display: block;
  }

  .auction-info-user .user-avatar img {
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 50%;
  }

  .auction-info-user .user-name {
    margin-left: 8px;
    font-size: 24px;
    font-weight: 500;
    color: #8b6244;
    line-height: 36px;
    max-width: 110px;
  }

  .auction-info-price {
    margin-left: 8px;
    font-size: 32px;
    font-weight: 600;
    color: #d5303e;
    position: relative;
    bottom: 2px;
  }

  .auction-info-bottom .is-confirm {
    font-size: 20px;
    font-weight: 500;
    color: #fb7575;
    margin-left: 8px;
  }

  .auction-info-bottom .is-wait {
    font-size: 20px;
    font-weight: 500;
    color: #c1955c;
    margin-left: 8px;
  }

  .auction-count {
    font-weight: 600;
    color: #c1955c;
  }
}
.color-h {
  font-size: 22px;
  color: #c1955c;
}
.jjjp {
  color: #d5303e;
}

.hide {
  opacity: 0;
}
</style>
