<template>
  <div
    v-if="isShow"
    class="show-qrcode-url item-flex-center"
    @click="closeAction"
  >
    <div
      class="show-qrcode-url-content"
      @click.stop="kong"
    >
      <div
        v-if="hasClose"
        class="close"
        @click="closeAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220503/131f2da5fa14c2ed80185f90f9bc4c95.png"
          alt=""
        >
      </div>
      <div class="t item-flex-center">
        <div class="h"></div>
        <div class="c">用户登录</div>
        <div class="h"></div>
      </div>
      <div class="content">
        <img
          :src="qrcode_url"
          alt=""
        >
      </div>
      <div class="bottom">
        <div>长按扫码关注公众号</div>
        <div>完成账号登录</div>
        <div
          class="is-can"
          @click="updateStatus"
        >我已关注</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { Toast } from 'vant';

export default {
  props: {
    isShow: Boolean,
    hasClose: String,
  },
  setup(props, content) {
    const { hasClose } = toRefs(props);
    const store = useStore();

    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });

    const closeAction = () => {
      if (hasClose.value === "1") {
        content.emit("close");
      }
    };

    const updateStatus = () => {
      store.dispatch("user/getIsFollowOfficialAccountInfo").catch(data => {
        Toast(data.message)
      });
    };

    const kong = () => {};

    return {
      qrcode_url,

      closeAction,
      updateStatus,
      kong,
    };
  },
};
</script>

<style lang="scss" scoped>
.show-qrcode-url {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);

  .show-qrcode-url-content {
    width: 520px;
    height: 670px;
    background: #fff;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.23);

    .close {
      width: 48px;
      height: 48px;
      position: absolute;
      right: 20px;
      top: 20px;

      img {
        width: 48px;
        height: 48px;
        display: block;
      }
    }

    .t {
      padding-top: 54px;
      height: 44px;
      font-size: 32px;
      font-weight: 500;
      color: #000000;
      line-height: 44px;

      .h {
        width: 90px;
        height: 2px;
        background: #eee;
        margin: 0 22px;
      }
    }

    .content {
      width: 320px;
      height: 320px;
      border: 2px solid #eeeeee;
      margin-top: 32px;
      margin-left: 98px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

      img {
        width: 320px;
        height: 320px;
      }
    }

    .bottom {
      margin-top: 32px;
      text-align: center;
      height: 80px;
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      line-height: 40px;

      .is-can {
        margin-top: 20px;
        color: #0088ff;
      }
    }
  }
}
</style>