<template>
  <div>
    <template v-if="transaction">
      <div class="done-bg"></div>
      <div class="done-view flex justify-c align-items-c">
        <div class="done-box">
            <div class="done">
              <img
                src="https://artimg2.artart.cn/image/20211225/b227adae766e9897b1775f7f453f26a8.png"
                mode=""
              >
            </div>
            <div class="done-he">
              <img
                src="https://artimg2.artart.cn/image/20211225/a6712e1ed6d025a40e646d93f38e22c7.png"
                mode=""
              >
            </div>
            <div class="done-hong">
              <img
                src="https://artimg2.artart.cn/image/20211225/bf50af9d588eaa7ce32c7fae721094da.png"
                mode=""
              >
            </div>
            <div class="done-huang">
              <img
                src="https://artimg2.artart.cn/image/20211225/4144426011af77931c4d6a1954f694cb.png"
                mode=""
              >
            </div>
            <div class="done-blue-add">
              <img
                src="https://artimg2.artart.cn/image/20211225/c21394dc079aa270e3a2800959e8ee7a.png"
                mode=""
              >
            </div>
            <div class="done-blue">
              <img
                src="https://artimg2.artart.cn/image/20211225/61bc27f5647ddede02466b4fa01bd75c.png"
                mode=""
              >
            </div>
            <div class="done-zhi">
              <img
                src="https://artimg2.artart.cn/image/20211225/c1cb40db4a03918e67a15cc7c55286a7.png"
                mode=""
              >
            </div>
            <div class="done-huang-dian">
              <img
                src="https://artimg2.artart.cn/image/20211225/02b502e0efc1b6f8e5230a6a6f4be63f.png"
                mode=""
              >
            </div>
            <div class="white-add">
              <img
                src="https://artimg2.artart.cn/image/20211225/fe8e793a0e1aad5e4dfa2e001b41f5de.png"
                mode=""
              >
            </div>
            <div class="done-zhi-dian">
              <img
                src="https://artimg2.artart.cn/image/20211225/02b502e0efc1b6f8e5230a6a6f4be63f.png"
                mode=""
              >
            </div>
        </div>
        <div class="liupai">
          <img
            class="bg-img"
            src="https://artimg2.artart.cn/image/20211226/389f1a8c3bb7e7e13ba1879f683b74a5.png"
          >
          <div class="liupai-txt fw500">
            <div class="liupai-line1 textAlignC">{{transaction.uname}}买家中拍</div>
            <div class="liupai-line2 ft28 textAlignC">{{transaction.lot}}号拍品</div>
            <div class="liupai-line3 ft28 textAlignC">落槌价{{transaction.price}}</div>
          </div>
          <div
            class="liupai-close flex justify-c"
            @click="closeCongratulationsAction"
          >
            <img src="https://artimg2.artart.cn/image/20211226/f447e35145009bd611a7236d13e2027e.png">
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transaction: null,
    }
  },
  methods: {
    closeCongratulationsAction() {
      this.transaction = null;
    },
  }
};
</script>

<style lang="scss" scoped>
.done-view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1002;
  flex-direction: column;
}



.done-bg {
  position: absolute;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .7);
}

.done-box {
  /* position: fixed;
	top: 554px;
	left: 0; */
  width: 750px;
  height: 220px;
  position: relative;
  /* z-index: 1002; */
}

.done-box img {
  width: 100%;
  height: 100%;
}

.done {
  width: 358px;
  height: 158px;
  position: absolute;
  right: 128px;
  transform-origin: 80%;
  transform: rotate(3deg);
  animation: downmove 1.3s 1;
  /* animation: downmove 1.8s infinite; */
}

.done img,
.done-he img {
  width: 100%;
  height: 100%;
}

@keyframes downmove {
  0% {
    transform: rotate(45deg);
  }

  75% {
    transform: rotate(80deg);
  }

  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(3deg);
  }
}

.done-he {
  width: 178px;
  height: 60px;
  position: absolute;
  left: 224px;
  bottom: 0;
  animation: zhendong 3s 1;
  animation-delay: 1.3s;
}

@keyframes zhendong {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  40% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1.3);
  }

  80% {
    transform: scale(1.1);
  }
}

.done-hong {
  width: 54px;
  height: 54px;
  position: absolute;
  left: 362px;
  bottom: 68px;
  opacity: 0;
  animation: donehongmove 3s 1;
  animation-delay: 1.3s;
}

@keyframes donehongmove {
  0% {
    /* left: 362px;
    bottom: 68px; */
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    left: 750px;
    bottom: 750px;
  }
}

.done-huang {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 416px;
  bottom: 100px;
  opacity: 0;
  animation: donehuangmove 3s 1;
  animation-delay: 1.3s;
}

@keyframes donehuangmove {
  0% {
    /* bottom: 0px;
    left: 313px; */
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    left: 750px;
    bottom: 750px;
  }
}

.done-blue {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 224px;
  bottom: 72px;
  animation: donebluemove 3s 1;
  animation-delay: 1.3s;
  opacity: 0;
}

@keyframes donebluemove {
  0% {
    /* bottom: 0px;
    left: 313px; */
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    left: 0px;
    bottom: 750px;
  }
}

.done-blue-add {
  width: 12px;
  height: 12px;
  left: 244px;
  bottom: 114px;
  position: absolute;
  opacity: 0;
  animation: donebluemove 3s 1;
  animation-delay: 1.3s;
}

.done-zhi {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 168px;
  bottom: 104px;
  animation: donebluemove 3s 1;
  animation-delay: 1.3s;
  opacity: 0;
}

.done-huang-dian {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 224px;
  bottom: 144px;
  animation: donebluemove 3s 1;
  animation-delay: 1.3s;
  opacity: 0;
}

.white-add {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 440px;
  bottom: 174px;
  animation: donehuangmove 3s 1;
  animation-delay: 1.3s;
  opacity: 0;
}

.done-zhi-dian {
  width: 8px;
  height: 8px;
  position: absolute;
  left: 408px;
  bottom: 124px;
  animation: donehuangmove 3s 1;
  animation-delay: 1.3s;
  opacity: 0;
}

.done-zhongppai {
  margin-top: 120px;
  display: flex;
  justify-content: center;
}

.done-zhongpai-wenzi {
  font-size: 32px;
  padding: 10px 26px;
  border-radius: 32px;
  color: #FFFFFF;
  background: #D5303E;
}

.liupai {
  width: 522px;
  height: 402px;
  margin-top: 56px;
  position: relative;
}

.liupai .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 522px;
  height: 322px;
  z-index: 1;
}

.liupai-txt {
  position: absolute;
  z-index: 2;
  top: 104px;
  left: 0;
  right: 0;
  color: #B65535;
}

.liupai-line1 {
  font-size: 48px;
}

.liupai-line3 {
  margin-top: 8px;
}

.liupai-close {
  margin-top: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.liupai-close img {
  width: 64px;
  height: 64px;
}

</style>
